import Icon from "@mdi/react";
import { mdiEmail } from "@mdi/js";
import { mdiLinkedin } from "@mdi/js";

function About() {
  return (
    <div className="about-text-wrapper">
      <div className="about-text">
        <p className="about-text-paragraph">
          I'm passionate about designing and developing user-centred digital
          products that make a difference in peoples' daily lives.
        </p>
        <p className="about-text-paragraph">
          My journey started as a Bachelor student in Communication Design at
          the Mainz University of Applied Science. During that time, I became
          more and more interested in web design and during my Erasmus Semester
          in France, I had the chance to implement my first little website. From
          that point I knew that I not only wanted to design web applications,
          but also bring them to life. So I decided to pursue a Master's degree
          in Software Design at the IT University of Copenhagen. I specialised
          in Frontend Development and UI Design which I continue to specialize
          in in my current position at Netcompany.
        </p>
        <p className="about-text-paragraph">
          Having both a designer's and a developer's perspective, I strive to
          balance the creative, user-focused aspects of design with the
          technical, practical aspects of development. I enjoy being part of the
          entire process — from the initial design to building a functional
          product — always striving to create high-quality software with a great
          user experience.
        </p>
        <div className="contacts">
          <p className="about-text-paragraph">I'd love to hear from you!</p>
          <div className="contact-item">
            <Icon path={mdiEmail} size={1} />
            <a className="link" href="mailto:elisabeth@elisabethfredrich.eu">
              elisabeth@elisabethfredrich.eu
            </a>
          </div>
          <div className="contact-item">
            <Icon path={mdiLinkedin} size={1} />
            <a href="www.linkedin.com/in/elisabeth-fredrich" className="link">
              www.linkedin.com/in/elisabeth-fredrich
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
