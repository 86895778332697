import { Grid, GridItem, Link } from "@chakra-ui/react";

import "./Projects.css";
import { Link as ReactRouterLink } from "react-router-dom";
import ProjectsContent from "./Projects-content";

function Projects() {
  return (
    <div className="project-wrapper">
      <Grid className="project-grid">
        {ProjectsContent.map((project, index) => (
          <Link
            className="project-title-link"
            key={index}
            as={ReactRouterLink}
            to={`/${project.title.replaceAll(" ", "-").toLowerCase()}`}
          >
            <GridItem className="project-title-picture">
              <img src={project.titlePicture} alt={project.title} />
              <p className="project-title-text">
                <span className="project-title">{project.title}</span>
                <br />
                {project.type}
              </p>
            </GridItem>
          </Link>
        ))}
      </Grid>
    </div>
  );
}

export default Projects;
