import { Grid, GridItem, Link, Tooltip } from "@chakra-ui/react";
import "./ProjectPage.css";
import projectsContent from "../Projects/Projects-content";
import { useParams } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { ForwardIcon as OutlineForwardIcon } from "@heroicons/react/24/outline";
import { ForwardIcon as SolidForwardIcon } from "@heroicons/react/24/solid";
import { BackwardIcon as OutlineBackwardIcon } from "@heroicons/react/24/outline";
import { BackwardIcon as SolidBackwardIcon } from "@heroicons/react/24/solid";

function ProjectPage() {
  const { project: projectName } = useParams();
  const [isHovered, setIsHovered] = useState(false);
  const projectData = projectsContent.find(
    (element) =>
      element.title.replaceAll(" ", "-").toLowerCase() === projectName
  );
  const currentIndex = projectsContent.indexOf(projectData);
  const descriptionParagraphs = projectData.description.split("<br>");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [projectName]);

  return (
    <div className="project-page-body">
      <Tooltip label="previous project">
        <Link
          as={ReactRouterLink}
          onClick={() => window.scrollTo(0, 0)}
          to={
            currentIndex > 0
              ? `/${projectsContent[currentIndex - 1].title
                  .replaceAll(" ", "-")
                  .toLowerCase()}`
              : `/${projectsContent[projectsContent.length - 1].title
                  .replaceAll(" ", "-")
                  .toLowerCase()}`
          }
          className="previous-project"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered ? <SolidBackwardIcon /> : <OutlineBackwardIcon />}
        </Link>
      </Tooltip>
      <div className="project-page-wrapper">
        <h1>{projectData.title}</h1>
        <h2>
          {projectData.type} &middot; {projectData.technology}
        </h2>
        <div className="project-description">
          {descriptionParagraphs.map((paragraph, index) => (
            <p className="project-description-paragraph" key={index}>
              {paragraph}
            </p>
          ))}
          <div className="project-links">
            {projectData.pageLink && (
              <p>
                👉 &nbsp;
                <Link
                  className="link-to-project"
                  href={projectData.pageLink}
                  isExternal
                >
                  check out the web page
                </Link>
              </p>
            )}
            {projectData.gitHub && (
              <p>
                👉 &nbsp;
                <Link
                  href={projectData.gitHub}
                  isExternal
                  className="link-to-project"
                >
                  GitHub
                </Link>
              </p>
            )}
          </div>
        </div>
        <Grid className="project-pictures-grid">
          {projectData.pictures.map(
            (medium, index) =>
              (medium.endsWith(".png") ||
                medium.endsWith(".jpg")) /* ? ( */ && (
                <GridItem
                  key={index}
                  className={
                    medium.includes("long")
                      ? "project-picture-long"
                      : "project-picture"
                  }
                >
                  <img src={medium} alt={projectData.title} />
                </GridItem>
              )
            /*   ) : (
              <GridItem className="project-video" key={index}>
                  <iframe
                    src={`https://player.vimeo.com/video/${medium}?badge=0&dnt=1&autoplay=1&muted=1&loop=1&controls=1`}
                    className="project-video-iframe"
                    allow="autoplay"
                    allowFullScreen
                    title={projectData.title}
                  ></iframe>
              </GridItem>
            ) */
          )}
        </Grid>
      </div>
      <Tooltip label="next project">
        <div className="next-project">
          <Link
            as={ReactRouterLink}
            to={
              currentIndex < projectsContent.length - 1
                ? `/${projectsContent[currentIndex + 1].title
                    .replaceAll(" ", "-")
                    .toLowerCase()}`
                : `/${projectsContent[0].title
                    .replaceAll(" ", "-")
                    .toLowerCase()}`
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered ? <SolidForwardIcon /> : <OutlineForwardIcon />}
          </Link>
        </div>
      </Tooltip>
    </div>
  );
}

export default ProjectPage;
