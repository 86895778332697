import "./NavBar.css";
import { Link } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiEmail } from "@mdi/js";
import { mdiLinkedin } from "@mdi/js";
function NavBar() {
  return (
    <div className="navbar">
      <div className="">
        <Link as={ReactRouterLink} to={"/"}>
          Elisabeth Fredrich
        </Link>
      </div>
      <div className="contacts-navbar">
        <div className="contact-item">
            <a href="mailto:elisabeth@elisabethfredrich.eu">
          <Icon path={mdiEmail} size={1} />
          </a>
        </div>
        <div className="contact-item">
            <a href="https://www.linkedin.com/in/elisabeth-fredrich/">
          <Icon path={mdiLinkedin} size={1} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
