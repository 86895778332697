import ColourBodySpaceTitle from "../assets/img/ColourBodySpace/cbs-title.png";
import LastWordsTitle from "../assets/img/LastWords/lw-title.png";
import TalkTitle from "../assets/img/Talk/talk-title.png";
import GeneralElectionTitle from "../assets/img/Election/election-title.png";

import GeneralElection1 from "../assets/img/Election/election-1.png";
import GeneralElection2 from "../assets/img/Election/election-2.png";
import GeneralElection3 from "../assets/img/Election/election-3.png";
import GeneralElection4 from "../assets/img/Election/election-4.png";
import GeneralElection5 from "../assets/img/Election/election-5.png";
import GeneralElection6 from "../assets/img/Election/election-6.png";

import LastWords1 from "../assets/img/LastWords/lw-1.png";
import LastWords2 from "../assets/img/LastWords/lw-2.png";
import LastWords3 from "../assets/img/LastWords/lw-3.png";
import LastWords4 from "../assets/img/LastWords/lw-4.png";
import LastWords5 from "../assets/img/LastWords/lw-5.png";
import LastWords6 from "../assets/img/LastWords/lw-6.png";

import ColourBodySpace1 from "../assets/img/ColourBodySpace/cbs-1.png";
import ColourBodySpace2 from "../assets/img/ColourBodySpace/cbs-2.png";
import ColourBodySpace3 from "../assets/img/ColourBodySpace/cbs-3.png";
import ColourBodySpace4 from "../assets/img/ColourBodySpace/cbs-4.png";
import ColourBodySpace5 from "../assets/img/ColourBodySpace/cbs-5.jpg";
import ColourBodySpace6 from "../assets/img/ColourBodySpace/cbs-6.jpg";

import Talk1 from "../assets/img/Talk/talk-1.png";
import Talk2 from "../assets/img/Talk/talk-2.png";
import Talk3 from "../assets/img/Talk/talk-3.png";
import Talk4 from "../assets/img/Talk/talk-4.png";
import Talk5 from "../assets/img/Talk/talk-5_long.png";
import Talk6 from "../assets/img/Talk/talk-6_long.png";

const projects = [
  {
    title: "E-Voting Usability Study",
    type: "UI Design & Development",
    technology: "React",
    titlePicture: GeneralElectionTitle,
    description:
      "For our master’s thesis, my thesis partner and I studied the usability, trust, and efficacy of detecting vote manipulation in e-voting systems. We explored different methods of vote verification that use verification codes in the context of general elections. Tracking code-based vote verification enables voters to verify their electronic vote after casting it, ensuring that their vote has not been corrupted. However, this additional step in the voting process presents challenges related to both security and usability, which we aimed to address in our study by examining the users' trust and user experience when using auch e-voting systems.<br>We specifically examined two types of verification codes: one where voters partially select their own verification code and another where the code is completely generated by the system. Voters can use their unique code to anonymously verify that the vote stored in the system matches their actual vote. To conduct a usability study with international voters, we designed and developed e-voting system prototypes using React. These prototypes were based on feedback from a previous study we conducted with Danish voters.",
    pictures: [
      GeneralElection1,
      GeneralElection2,
      GeneralElection3,
      GeneralElection4,
      GeneralElection5,
      GeneralElection6,
    ],
    gitHub:
      "https://github.com/elisabethfredrich/e-voting-input-code-vote-modification",
  },
  {
    title: "Colour Body Space",
    type: "Corporate & Web Design",
    technology: "Adobe CC, Webflow",
    titlePicture: ColourBodySpaceTitle,
    description:
      "'Farbe Körper Raum' (Colour Body Space) was my bachelor project. I created a visual identity for the freelance artist ColourBodySpace Kim whose space-filling, minimalist works are characterised by radiant areas of colour and her unusual technique of ‘painting’ them with cotton yarn, which she wraps tightly around cuboids and cubes.<br>The aim of the visual identity was to enable the artist to present herself and her art across a wide range of media and to stand out more strongly in the competitive art market. The design of the visual identity takes up the simple, minimalist geometric style and the intense colour surfaces of her works and emphasises the special surface structure of the cotton yarn. The five colours defined for the visual identity are derived from a series of her yarn works. The logo reflects the artist's Korean roots: her Korean surname is combined with her German initials.<br>The central medium of the project is the website, where visitors can get to know the artist and her work and gain an insight into her working methods through a short photo reportage. In addition to a social media concept and the classic print media of business cards, letterheads and postcards, a poster, a flyer and an invitation to a fictitious solo exhibition by the artist were also created.",
    pictures: [
      ColourBodySpace1,
      ColourBodySpace2,
      ColourBodySpace3,
      ColourBodySpace4,
      ColourBodySpace5,
      ColourBodySpace6,
      "1013833928",
    ],
  },
  {
    title: "Last Words",
    type: "Web & Information Design",
    technology: "Webflow",
    titlePicture: LastWordsTitle,
    description:
      "I love the fact that learning a language allows me to immerse myself much more intensively in the culture of its speakers and get to know them in a completely different way. Every language has words and expressions that simply cannot be translated. Languages carry culture, traditions, stories and knowledge. But all of this could be lost very soon for far too many languages. In the truest sense of the word, they would then be lost in translation.<br>At the end of my bachelor's degree, I worked on a project about language extinction and was surprised to realise how many languages there are and how many of them really are threatened with extinction. The result is a one-pager with facts and knowledge about the richness and diversity of languages and what we lose when they die.",
    pictures: [
      LastWords1,
      LastWords2,
      LastWords3,
      LastWords4,
      LastWords5,
      LastWords6,
      "1013812637",
    ],
    pageLink: "https://LastWords.webflow.io",
  },
  {
    title: "Talk!",
    titlePicture: TalkTitle,
    type: "UI Design & Development",
    technology: "Figma, React",
    description:
      "Talk! is a live chat application that I developed as a semester project together with two fellow students during my Master’s studies.<br>We created a design system in Figma, where we established a cohesive visual language for the application. We then designed a logical data model to support the app’s functionality. Following this, we implemented the application using React and integrated Parse Server and REST API to effectively manage users and chat interactions.<br>The primary goal of Talk! is to connect individuals who are learning a foreign language with native speakers, facilitating language practice and skill improvement. Additionally, the application offers group chats on various topics, providing a supportive community for learners of the same language.",
    pictures: [Talk1, Talk2, Talk3, Talk4, Talk5, Talk6],
    gitHub: "https://github.com/elisabethfredrich/talk",
  },
];
export default projects;
