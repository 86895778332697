import "./App.css";
import Projects from "./Projects/Projects";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
import About from "./About";
import { useEffect } from "react";

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="headline">Hej! I'm Elisabeth.</h1>
        <h3 className="subline">
          I'm a Frontend Developer and UI Designer based in Copenhagen.
        </h3>
      </header>
      <div className="panels">
        <Tabs marginTop={"50px"}>
          <TabList className="tablist" justifyContent={"center"}>
            <Tab className="tab">Work</Tab>
            <Tab className="tab">About me</Tab>
          </TabList>
          <TabIndicator
            mt="1.5px"
            height="1px"
            bg="#ffffff"
            borderRadius="1px"
            justifyContent={"center"}
          />
          <TabPanels>
            <TabPanel className="work-panel" marginTop={"50px"}>
              <Projects />
            </TabPanel>
            <TabPanel>
              <About />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
}

export default HomePage;
