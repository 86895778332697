import "./Footer.css";
import { Link } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useEffect } from "react";


function Footer() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div className="footer">
      <p>&copy; Elisabeth Fredrich | 2024</p>
      <Link as={ReactRouterLink} to={`/imprint`} className="link">
        <p>Imprint</p>
      </Link>
    </div>
  );
}

export default Footer;
