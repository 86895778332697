import { useEffect } from "react";
import "./Imprint.css";

function Imprint() {

    useEffect(() => {
        window.scrollTo(0,0);
      }, []);

  return (
    <div className="imprint">
      <h1>Imprint / Impressum</h1>
      <h2>Owner of the Website (Site Operator) and Responsible for Content</h2>
      <p>
        Elisabeth Fredrich
        <br />
        Email:{" "}
        <a href="mailto:elisabeth@elisabethfredrich.eu">
          elisabeth@elisabethfredrich.eu
        </a>{" "}
        <p />
        <h2>Disclaimer</h2>
        <h3>General Information</h3>
        <p>
          The use of this website is possible without providing personal data.
          However, please be aware that data transmission over the internet can
          have security vulnerabilities. A complete protection of data against
          unauthorized access by third parties is not possible.
        </p>
       
        <h3>Accountability for Content</h3>
        The content on this website was created with great care. However, I
        cannot guarantee the accuracy, completeness, or timeliness of the
        information provided. I am responsible for my content on this website according to general laws. However, I am not
        obliged to monitor third-party information transmitted or stored or to
        search for circumstances indicating illegal activity. Obligations to
        remove or block the use of information under general law remain
        unaffected. Any liability in this regard, however, is only possible from
        the point at which knowledge of a specific legal infringement is
        obtained. Upon becoming aware of such infringements, I will immediately
        remove the respective content.
      </p>
      <h3>Accountability for Links</h3>
      <p>
        This website contains links to external websites. I have no influence on
        the content of those sites, and therefore, I cannot assume any
        responsibility for their content. The respective providers or operators
        of the linked pages are always responsible for their content. At the
        time of linking, no unlawful content was identifiable. Continuous
        monitoring of the content of the linked pages is not reasonable without
        concrete evidence of an infringement. Upon notification of violations, I
        will promptly remove such links.
      </p>
      <h3>Copyright</h3>
      <p>
        The content and works on these pages created by the site operator are
        subject to copyright law. Duplication, processing, distribution, or any
        form of commercialization of such material beyond the scope of copyright
        law requires the prior written consent of the author or creator.
        Downloads and copies of this site are only permitted for private,
        non-commercial use. Insofar as the content on this site was not created
        by the operator, third-party copyrights are respected. Should you
        nevertheless become aware of a copyright infringement, please notify me.
        Upon notification of violations, I will remove such content immediately.
      </p>
    </div>
  );
}

export default Imprint;
