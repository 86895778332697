import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ProjectPage from "./ProjectPage/ProjectPage";
import About from "./About"
import NavBar from "./NavBar/NavBar";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import Imprint from "./Imprint/Imprint";
import Footer from "./Footer/Footer";
import HomePage from "./HomePage";


function App() {
  return (
      <ChakraProvider theme={theme}>
    <BrowserRouter>
      <NavBar/>
    <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/:project" element={<ProjectPage/>}/>
            <Route path="/about" element={<About />} />
            <Route path="/imprint" element={<Imprint />} />
    </Routes>
    <Footer/>
    </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
