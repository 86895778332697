import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        color: "#4a0f0f",
        _hover: {
          textDecoration: "none",
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        color: "#4a0f0f !important",
      },

      ".App": {
        textAlign: "center",
      },

      ".profile-picture": {
        height: "40vmin",
        pointerEvents: "none",
        borderRadius: "100%",
      },
      ".picture-frame": {
        borderRadius: "50%",
        height: "41vmin",
        width: "41vmin",
        border: "solid #4a0f0f",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },

      ".links": {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "20px",
      },

      ".App-link": {
        color: "#61dafb",
      },

      ".contact-wrapper": {
        display: "flex",
        justifyContent: "center",
      },

      ".contact-grid": {
        gridAutoFlow: "column",
        gridAutoColumns: "max-content",
        gap: "20px",
        marginBottom: "60px",
      },

      ".panels": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "2rem",
      },

      ".tab": {
        color: "#4a0f0f",
        border: "none",
      },

      ".chakra-tabs__tab-indicator": {
        display: "none !important",
      },

      h4: {
        marginTop: "0",
        fontWeight: "600",
      },
    },
  },
});

export default theme;
